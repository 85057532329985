import React from 'react';

const Capability = ({ heading, subHeading, description, iconClass, bullets, i }) =>
  <section className={`wrapper style${i%3}`}>
    {/*<span className={`icon major ${iconClass}`} />*/}
    <h2>{heading}</h2>
    <h3>{subHeading}</h3>
    <p>{description}</p>
    {bullets.length ? (
    	<ul>
			{bullets.map((bullet, i) => <li key={i}>
				{bullet}
			</li>)}
    	</ul>
    ) : null}
  </section>

export default Capability;
