import React from 'react';

import pic from '../images/espinhaco3_mar10_72.jpg';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import CapabilityList from '../components/capabilities/CapabilityList';


const Tours = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Tours and Lessons</h1>
          <span className="image fit">
            <img src={pic} alt="" data-position="center center" />
          </span>
          <p>
            If you don't see exactly what you want, just let us know what we can do for you! Custom courses available.
          </p>
          <div className="features">
            <CapabilityList />
          </div>
          <ul className="actions centered">
            <li>
              <a href="/contact" className="button primary">
                Book now
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default Tours;
