import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'Introduction to Rock-Climbing and Abseiling',
      subHeading: '½ Day 50€ pax',
      description: "First time climbing? Enjoy this exciting half day climbing and abseiling experience in a fantastic place under the Moorish Castle with amazing views over the Atlantic and Sintra’s forest and Palaces.",
      bullets: [],
      iconClass: 'fa-diamond',
    },
    {
      heading: 'Learn to Climb: Weekender',
      subHeading: "3 day long weekends course 180 € pax (min 2 pax)",
      description: 'You’ve never climbed or you want to improve your safety and climbing techniques, this is the course for you!',
      bullets: [
        'For beginners and intermediate climbers',
        'Starting and improvement courses to learn all the basic skills to lead climbing on sport routes with complete safety ',
        'Customized classes, small groups.'
      ],
      iconClass: 'fa-lock',
    },
    {
      heading: 'Learn to Climb: 5 Days',
      subHeading: '5 half-day course 200 € pax (min 2 pax)',
      description: "You’ve never climbed or you want to improve your safety and climbing techniques, this is the course for you!",
      bullets: [
        'For beginners and intermediate climbers',
        'Starting and improvement courses to learn all the basic skills to lead climbing on sport routes with complete safety.',
        'Customized classes, small groups.'
      ],
      iconClass: 'fa-cog',
    },
    {
      heading: 'CRACK CLIMBING',
      subHeading: '2 day course 150 € pax (min 2 pax)',
      description: "In Casal Pianos and Cabo da Roca, only 15’ from Sintra, you can find some of the best crack climbing in Europe with perfect splitters and all kind of difficulties and techniques. ",
      bullets: [
        'For all levels',
        'Improvement course to learn or improve the crack climbing techniques ',
        'Customized classes, small groups.'
      ],
      iconClass: 'fa-desktop',
    },
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability, i) => <Capability key={capability.heading} {...capability} i={i}/>)}
    </>
  )
}

export default CapabilityList;
